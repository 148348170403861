import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//import "./assets/scss/style.scss";
import "./assets/App.css";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Users = lazy(() => import("./pages/Users"));
const Customers = lazy(() => import("./pages/Customers"));
const Catalog = lazy(() => import("./pages/Catalog"));
const AddSales = lazy(() => import("./pages/AddSales"));
const AddCustomer = lazy(() => import("./pages/AddCustomer"));
const Billing = lazy(() => import("./pages/Billing"));
const SpecialOrder = lazy(() => import("./pages/SpecialOrder"));
const QualityControl = lazy(() => import("./pages/QualityControl"));
const Invoices = lazy(() => import("./pages/Invoices"));
const Productions = lazy(() => import("./pages/Productions"));
const Inventory = lazy(() => import("./pages/Inventory"));
const DeliveryOrders = lazy(() => import("./pages/DeliveryOrders"));
const DeliveryPickupOrders = lazy(() => import("./pages/DeliveryPickupOrders"));
const Categories = lazy(() => import("./pages/Categories"));
const AddItem = lazy(() => import("./pages/AddItem"));
const StockIn = lazy(() => import("./pages/StockIn"));
const AddStockIn = lazy(() => import("./pages/AddStockIn"));
const GoodsWarehouse = lazy(() => import("./pages/GoodsWarehouse"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              component={Login}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login"}
              render={(props) => <Login {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/dashboard"}
              exact
              component={Dashboard}
            />
            <Route
              path={process.env.PUBLIC_URL + "/users"}
              render={(props) => <Users {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/customers"}
              render={(props) => <Customers {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/addcustomer"}
              render={(props) => <AddCustomer {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/billing"}
              render={(props) => <Billing {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/catalog"}
              render={(props) => <Catalog {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/addsales"}
              render={(props) => <AddSales {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/specialorder"}
              render={(props) => <SpecialOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/quality-control"}
              render={(props) => <QualityControl {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/invoices"}
              render={(props) => <Invoices {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productions"}
              render={(props) => <Productions {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/inventory"}
              render={(props) => <Inventory {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/delivery-orders"}
              render={(props) => <DeliveryOrders {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/delivery-pickup-orders"}
              render={(props) => <DeliveryPickupOrders {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/categories"}
              render={(props) => <Categories {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-item"}
              render={(props) => <AddItem {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/stock-in"}
              render={(props) => <StockIn {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/add-stock-in"}
              render={(props) => <AddStockIn {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/goods-warehouse"}
              render={(props) => <GoodsWarehouse {...props} />}
            />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(App);
